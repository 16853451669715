import { template as template_f3b97308ab2445a4b44d728ed054c93a } from "@ember/template-compiler";
const SidebarSectionMessage = template_f3b97308ab2445a4b44d728ed054c93a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
