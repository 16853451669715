import { template as template_3d3c9173a82a4c9f95ea763e6154c6c3 } from "@ember/template-compiler";
const WelcomeHeader = template_3d3c9173a82a4c9f95ea763e6154c6c3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
